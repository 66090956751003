<template>
  <div>
    <p>Thank you for signing the document. A copy of the signed document has been added to your file and forwarded to your advisor. It is possible to download this document and others from the client portal.</p>
  </div>
</template>

<script>
import { dateFormat } from "@/mixins/dateFormat";

export default {
  name: 'DocumentSignatureComplete',
  mixins: [dateFormat],
  props: {
    signatureData: {
      type: Object,
      required:true
    }
  }
}
</script>
