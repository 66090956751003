<template>
  <div>
    <p>Draw your signature within the box below</p>
    <div class="signature-pad">
      <VueSignaturePad
          width="100%"
          height="300px"
          ref="signaturePad"
          :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
      />
    </div>

  </div>
</template>

<script>
import {VueSignaturePad} from 'vue-signature-pad';
import {httpQuestionnaire} from "@/services";
import axios from "axios";
import {toast} from '@/mixins/toast'


export default {
  name: 'DocumentSignaturePad',
  mixins: [toast],
  components: {
    VueSignaturePad
  },
  props: {
    signatureData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      working: false,
    }
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    async save() {
      this.working = true
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.working = false
        return this.toastError('Please make you signature before singing')
      }
      this.signatureImage = fetch(data)
          .then(response => response.blob()
          ).then(
              blob => this.signatureImage = blob
          )
      let signatureData = await this.getAWSSignature()
      let image = await this.uploadToAmazon(signatureData, this.signatureImage);

      let patchData = {
        id: this.signatureData.id,
        image: image
      }

      httpQuestionnaire.patch('document_signature_detail', patchData).then(
          (response) => {
            this.working = false
            this.$emit('saved', response.data)
          }
      ).catch(
          error => {
            console.log(error)
            this.working = false
            this.toastError('Error uploading signature')
          }
      )
    },
    async getAWSSignature() {
      // get aws s3 signature from kernel server
      let path = `signature${this.signatureData.id}${new Date().toISOString()}.png`
      let data = {
        contentType: 'image/png', // content type of image from signature pad is always png
        filePath: path,
        signature: this.signatureData.id,
      };

      let instance = axios.create({
        headers: {
          Authorization: this.$store.getters.accessCode ? `Code ${this.$store.getters.accessCode}` : `JWT ${this.$store.getters.tokens.access}`
        }
      })

      return instance.post(`${process.env.VUE_APP_BACKEND_URL}/api/upload_pre_sign`,
          data).then((response) => {
        return response.data
      }).catch((error) => {
        this.toast('Error uploading image', 'Error', 'danger')
        console.log(error);
      });
    },
    async uploadToAmazon(response, imageData) {
      // upload to aws s3 with signature
      let formData = new FormData();
      let keys = Object.keys(response.signature);
      for (let i in keys) {
        formData.append(keys[i],
            response.signature[keys[i]]);
      }
      // attach file to upload on form as blob
      formData.append('file', imageData);

      return axios.post(response.postEndpoint, formData).then(() => {
        return response.signature.key;
      }).catch((error) => {
        console.log(error);
        return false;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.signature-pad {
  border-color: darkgrey;
  border-style: dashed;
  border-width: thin;
  border-radius: 20px;
}
</style>