<template>
  <b-modal
      v-model="showModel"
      aria-label=""
      body-class="modal-form"
      modal-class="modal-style-one modal-background-white questionnaire"
      scrollable
      size="md"
      centered
      :title="`${signature.document.reference}`"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @ok="ok"
      @shown="shown"
  >

      <p class="mt-12">Your advisor has requested that you electronically sign the {{ signature.document.reference }} document.</p>
      <p class="mb-20">Once signed the document will be legally binding, just like a paper copy.</p>


    <template #modal-footer="{ ok, cancel }">
      <div class="d-flex justify-content-between w-100 p-2">
        <b-button
            size="sm"
            variant="link"
            @click="cancel"
        >
          Cancel
        </b-button>

        <b-button
            class="ml-2"
            size="md"
            variant="secondary ripple"
            :disabled="working"
            @click="ok"
        >
          Continue
          <b-spinner
              v-if="working"
              class="small-spinner align-middle ml-1"/>
        </b-button>

      </div>

    </template>

  </b-modal>
</template>


<script>

import {httpQuestionnaire} from "@/services";
import {toast} from '@/mixins/toast'

export default {
  name: 'DocumentSignatureLegalApproveModal',
  mixins: [toast],
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true
    },
    signatureData: {
      type: Object,
      required: true
    }
  },
  computed: {
    showModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    signature: {
      set(value) {
        this.$emit('signatureUpdate', value)
      },
      get() {
        return this.signatureData
      }
    }
  },
  data() {
    return {
      working: false
    }
  },
  methods: {
    ok(bvEvent) {
      bvEvent.preventDefault()
      this.confirm()
    },
    shown() {
    },
    confirm() {
      this.working = true
      let data = {
        id: this.signatureData.id,
        disclosure_consent_approved_date: new Date()
      }
      httpQuestionnaire.patch('document_signature_detail', data).then(
          (response) => {
            this.signature.disclosure_consent_approved_date = response.data.disclosure_consent_approved_date
            this.working = false
            this.$nextTick(() => {
              this.closeModal()
              this.$emit('confirmed')
            })
          }
      ).catch(
          error => {
            this.working = false
            console.log(error)
          }
      )
    },
    closeModal() {
      this.showModel = false
    }
  }

}
</script>

