<template>
  <vue-dropzone
      id="dropzone"
      ref="dropzone"
      :awss3="awsS3"
      :options="dropzoneOptions"
      :disabled="disabled"
      class="d-flex flex-row"
      @vdropzone-processing="working(true)"
      @vdropzone-error="handleError"
      @vdropzone-success="createDocument"
      @vdropzone-s3-upload-error="s3UploadError"
      @vdropzone-s3-upload-success="s3UploadSuccess"
  ></vue-dropzone>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import {http, httpQuestionnaire} from "@/services";
import {toast} from "@/mixins/toast";

export default {
  name: "DocumentSignatureDropZoneCard",
  mixins: [toast],
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    signature: {
      type: Object,
      required: true,
    },
    dropMessage: {
      type: String,
      default: "Drag file here, or click"
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed: {
    awsS3() {
      let params = {
        signature: this.signature.id,
      }

      return {
        signingURL: `${process.env.VUE_APP_BACKEND_URL}/api/upload_pre_sign`,
        headers: {
          Authorization: this.$store.getters.accessCode ? `Code ${this.$store.getters.accessCode}` : `JWT ${this.$store.getters.tokens.access}`
        },
        params: params,
        sendFileToServer: false,
        withCredentials: true,
        disablePreviews: true,
        acceptedFiles: '.svg,.png',
        maxFilesize: 1,
        maxFiles: 1,
      };
    },
  },
  data() {
    return {
      documentType: null,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_BACKEND_URL}/api/upload_pre_sign`,
        maxFilesize: 1,
        maxFiles: 1,
        headers: {},
        addRemoveLinks: true,
        dictDefaultMessage: this.dropMessage,
        autoProcessQueue: true,
        acceptedFiles: '.svg,.png', // svg or png only allow for pdf render
      },
    };
  },
  methods: {
    uploadManual() {
      this.$refs.documentDropzone.$refs.dropzoneElement.click();
    },
    addedFile(file) {
      console.log("added file", file);
    },
    s3UploadError(errorMessage) {
      console.log("s3 upload error", errorMessage);
    },
    s3UploadSuccess(s3ObjectLocation) {
      console.log("s3 upload success", s3ObjectLocation);
    },
    handleError(error, message, other) {
      console.log('handle error')
      console.log(error, message, other)
    },
    working(value) {
      this.$emit('working', value)
    },
    createDocument(file) {
      this.working(true)
      let url = 'document_signature_detail'

      let data = {
        id: this.signature.id,
        image: file.s3Signature.key,
      }

      if (this.$store.getters.accessCode) {
        httpQuestionnaire.patch(url, data).then((response) => this.createDocumentDone(file, response.data, !!this.request)).catch((error) => {
          this.toast(error.response.data, 'Error', 'danger');
          this.working(false)


        });
      } else {
        http.post(url, data).then((response) => this.createDocumentDone(file, response.data, !!this.request)).catch((error) => {
          this.toast(error.response.data, 'Error', 'danger');
          this.working(false)

        });
      }

    },
    createDocumentDone(file, data, removeFile = true) {
      this.toast("Signature uploaded");
      this.$emit('saved', data)
      // remove file from queued files
      if (removeFile) this.$refs.dropzone.removeFile(file);
      // if no files left for uploading then emit done (close modal)
      let docs = this.$refs.dropzone.getUploadingFiles();
      if (!docs.length) this.$emit("done");
      this.working(false)
    }
  },
};
</script>
