<template>
  <div class="fieldset" id="fieldset">
    <h3 class="fieldset-heading">{{ value.signatures[parentIndex].document.reference }}</h3>

    <b-alert v-if="!form.signatures[parentIndex].date && form.signatures[parentIndex].response" show variant="danger">
      {{ form.signatures[parentIndex].response }}
    </b-alert>

    <template v-if="form.signatures[parentIndex].date">
      <DocumentSignatureComplete :signature-data="form.signatures[parentIndex]"/>
    </template>

    <div v-show="!form.signatures[parentIndex].date">

      <DocumentSignatureLegalStatus
          v-if="!form.signatures[parentIndex].disclosure_consent_approved_date"
          :signature-data="form.signatures[parentIndex]"
          @confirm="show.legalModal=true"
      />

      <DocumentSignatureLegalApproveModal
          v-model="show.legalModal"
          :signature-data="form.signatures[parentIndex]"
          @signatureUpdate="form.signatures[parentIndex]=$event"
          @confirmed="legalConfirmed"
      />

      <div v-if="form.signatures[this.parentIndex].disclosure_consent_approved_date" class="section-signatures">
        <SignatureSection
            v-for="(section, index) in form.signatures[parentIndex].document.data.documentSignatureTemplate.sections"
            :key="`documentSignatureSection${index}`"
            :index="index"
            :parentIndex="parentIndex"
            :signatureData="form.signatures[parentIndex]"
            v-model="form.signatures[parentIndex].document.data.documentSignatureTemplate.sections[index].signatures[`client_${form.signatures[parentIndex].client}`].date"
            @signatureUpdate="form.signatures[parentIndex]=event"
            @signed="checkForFinalSignature"
        />
      </div>
    </div>

    <transition name="fade">
      <b-overlay
          :show="show.loadingPdf"
          rounded="sm"
          spinner-type="border"
          spinner-variant="primary"
      >
        <p v-if="show.loadingPdf && !finishedDocument">Loading Preview</p>
        <div v-if="finishedDocument" class="text-right mb-4">
          <b-button  @click="download">
            <i class="text-20 i-Download mr-2"/>Download Document
          </b-button>
        </div>


        <b-card class="mt-30 mb-30" no-body  v-if="finishedDocument"  style="height: 500px; border-radius: 0;">
          <div>
            <iframe
                :srcdoc="finishedDocument"
                width="100%"
                height="500px"
                style="border: none; padding: 20px"
            >

            </iframe>
          </div>
        </b-card>
      </b-overlay>
    </transition>
  </div>
</template>

<script>
import {httpQuestionnaire} from '@/services'
import {fieldsetHelpers} from "../fieldsetHelpers";
import {scrollTo} from "@/mixins/scrollTo";
import SignatureSection
  from "@/components/common/questionnaires/question/questions/documents/signature/SignatureSection";
import DocumentSignatureComplete from "@/components/common/documents/signature/DocumentSignatureComplete";
import DocumentSignatureLegalApproveModal
  from "@/components/common/documents/signature/DocumentSignatureLegalApproveModal";
import DocumentSignatureLegalStatus from "@/components/common/documents/signature/DocumentSignatureLegalStatus";

export default {
  // name: 'DocumentSignature', // name assigned on load dynamically: DO NOT CHANGE/NAME
  mixins: [fieldsetHelpers, scrollTo],
  props: {
    value: {
      type: Object,
      required: true
    },
    parentIndex: {
      type: Number,
      required: true
    }
  },
  components: {
    DocumentSignatureLegalStatus,
    DocumentSignatureLegalApproveModal,
    DocumentSignatureComplete,
    SignatureSection
  },
  mounted() {
    // change next button text on fieldset load
    if (!this.form.signatures[this.parentIndex].disclosure_consent_approved_date) {
      this.$emit('continueButtonText', 'Next')
      this.$emit('finishButtonText', 'Finish')
    } else if (!this.form.signatures[this.parentIndex].date) {
      this.$emit('continueButtonText', 'Complete Signing')
      this.$emit('finishButtonText', 'Complete Signing')
    } else {
      this.$emit('continueButtonText', 'Next')
      this.$emit('finishButtonText', 'Finish')
    }
    this.show.legalModal = !this.form.signatures[this.parentIndex].disclosure_consent_approved_date;
    this.scrollTo('#top')
  },
  data() {
    return {
      label: this.value.signatures[this.parentIndex].document.reference, // used for steps progress
      selected: {
        section: null
      },
      navigateNextFunction: this.confirm,
      show: {
        legalModal: false,
        loadingPdf: false
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    finishedDocument() {
      if (this.form.signatures[this.parentIndex].date && this.form.signatures[this.parentIndex].document?.data?.html && this.form.signatures[this.parentIndex].image) {
        return this.form.signatures[this.parentIndex].document.data.html
      }
      return null
    }
  },
  methods: {
    checkForFinalSignature() {
      // if user has signed the final signature box then auto confirm document signature

      if (this.form.signatures[this.parentIndex].document.data.documentSignatureTemplate.sections.filter(
          section => {
            // could be multiple clients signing the document
            return section.signatures.client_1.date
          }).length === this.form.signatures[this.parentIndex].document.data.documentSignatureTemplate.sections.length) {
        // if all sections have been signed then confirm
        this.confirm()
      }

    },
    async confirm() {
      // if not legal confirm goto next signature
      if (!this.form.signatures[this.parentIndex].disclosure_consent_approved_date) return true
      // if document signed then goto next signature
      if (this.form.signatures[this.parentIndex].date) return true
      // else sign
      return this.completeSignedDocument()
      // return this.$bvModal.msgBoxConfirm('I have read through the document and I agree',
      //     {
      //       title: 'Please Confirm',
      //       size: 'sm',
      //       buttonSize: 'sm',
      //       cancelVariant: 'outline-primary',
      //       okVariant: 'secondary',
      //       okTitle: 'Confirm',
      //       cancelTitle: 'Cancel',
      //       footerClass: 'p-2',
      //       hideHeaderClose: false,
      //       centered: true
      //     }).then(async (confirmed) => {
      //   if (confirmed) {
      //     return this.completeSignedDocument()
      //   }
      //   return false
      // }).catch(() => {
      //   return false
      // });
    },
    async completeSignedDocument() {
      // produce pdf; complete signature; disable editing
      let data = {
        id: this.form.signatures[this.parentIndex].id,
        date: new Date(),
        identity_method: 'verificationMethod' in this.$route.params ? this.$route.params.verificationMethod : null,
        identity_method_date: 'verificationMethod' in this.$route.params ? new Date() : null
      }
      return httpQuestionnaire.patch('document_signature_detail', data).then(
          response => {
            // update signature date to disable editing
            this.form.signatures[this.parentIndex].date = response.data.date
            this.scrollTo('#top')
            return this.createPdf()
          }
      ).catch(
          error => {
            console.log(error)
            return false
          }
      )
    },
    async createPdf() {
      this.show.loadingPdf = true
      let data = {
        id: this.form.signatures[this.parentIndex].id,
      }

      return httpQuestionnaire.post('sign_document', data).then(
          response => {
            this.show.loadingPdf = false

            this.form.signatures[this.parentIndex].document.data.html = response.data.document.data.html
            this.$emit('continueButtonText', 'Next Document')
            if (this.parentIndex + 1 === this.form.signatures.length) {
              this.$emit('finishButtonText', 'Finish')
            }
            return false
          }
      ).catch(
          error => {
            this.show.loadingPdf = false
            console.log(error)
            return false
          }
      )
    },
    legalConfirmed() {
      this.$nextTick(() => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
        this.$emit('finishButtonText', 'Complete Signing')
      })
    },
    download() {
      httpQuestionnaire.post(
          'download_document',
          { id: this.form.signatures[this.parentIndex].document.id },
          {
            responseType: 'arraybuffer'
          }
      )
          .then((response) => {
            this.openFile(response, this.form.signatures[this.parentIndex].document.reference);
          })
          .catch((error) => {
            console.log(error);
          });

    },openFile(response, filename) {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], {
        type: type,
        encoding: 'UTF-8'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      link.remove();
    }

  }
};
</script>
<style lang="scss">
.height-preview {
  width: 100%;
  max-height: 500px;
}
.section-signatures .question-box {

  @page {
    size: A4;
    margin-bottom: 2cm;
    @bottom-right {
      content: 'Page ' counter(page) ' of ' counter(pages);
      font-size: 0.9rem;
    }
  }

  .new-page {
    page-break-before: always !important;
  }

  :root {
    font-size: 0.9rem;
  }

  html {
    white-space: nowrap;
  }

  h1 {
    font-family: "Times New Roman", Times, serif;
    font-size: 3rem;
    font-weight: 400;
  }

  .text-center {
    text-align: center;
  }

  h4 {
    font-family: "Times New Roman", Times, serif;
    font-size: 1rem;
    font-weight: 400;
  }

   p {
    text-align: justify;
    white-space: normal;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    display: block;
    margin: 0;
    padding: 0;
  }
  p, ol, ul {
    white-space: normal;
    font-family: "Times New Roman", Times, serif;
    line-height: 1rem;
    font-size: 0.8rem;
    margin-top: 10px;
  }

  strong {
    font-weight: 600;
    font-family: "Times New Roman", Times, serif;
  }

  body > p {
    margin-bottom: 10px !important
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    padding-left: 25px;
  }

  ol > li {
    counter-increment: item;
    position: relative;
    margin-top: 10px;
  }

  ol > li > strong:not(.text-red) {
    display: block;
    margin-bottom: 10px;
  }

  li ol > li > strong:not(.text-red) {
    display: block;
    margin-bottom: 5px;
  }

  body > ol > li {
    margin-bottom: 25px
  }

  body > ol > li, .commentary-snippet {
    margin-bottom: 25px;
  }

  .commentary-snippet {
    font-style: italic;
    font-weight: 600;
  }

  body > ol > li li {
    margin-top: 10px;
    margin-bottom: 10px
  }


  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 5px;
    width: 25px;
    font-weight: 600;
    font-family: "Times New Roman", Times, serif;
    position: absolute;
    left: -25px
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }

  li ol > li ol > li:before {
    content: counter(item, lower-roman) ") ";
  }

  li ol > li > ol > li > ol > li:before {
    content: counter(item, lower-alpha) ") ";
  }

  li ol > li > ol > li > ol > li > ol > li:before {
    content: counter(item, lower-roman) ") ";
  }

  .w-50 {
    width: 50%;
  }

  .pl-48 {
    padding-left: 48px;
  }

  .pl-100 {
    padding-left: 48px;
  }

  .mt-28 {
    padding-top: 28px;
  }

  .mt-48 {
    padding-top: 48px;
  }

  .mt-100 {
    padding-top: 100px;
  }

  .mb-48 {
    padding-bottom: 48px;
  }

  .mb-100 {
    padding-bottom: 100px;
  }

  .mb-350 {
    margin-bottom: 350px;
  }

  .mb-400 {
    margin-bottom: 400px;
  }

  .document-title,
  .document-title p {
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 45px;
  }

  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }

  .text-center {
    text-align: center
  }

  .text-right {
    text-align: right
  }

  .text-left {
    text-align: left
  }

  hr {
    width: 60%;
    margin-top: 11px;
    margin-bottom: 20px;
    border-width: 2px;
    border-color: #efeeee;
    border-style: solid;
  }

  .clearfix {
    clear: both;
  }

  /* Needed for document signatures */
  .section {
    display: block;
  }

  .signature-image {
    width: 150px
  }

  .signature-container {
    display: block;
    margin-bottom: 20px;
  }

}

</style>

