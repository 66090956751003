<template>
  <QuestionnaireBase
      :title="title"
      :form="form"
      :finishFunction="null"
      :finishButtonText="finishButtonText"
      :continueButtonText="continueButtonText"
  >
    <template v-slot:default>

      <!--allows multiple fields set that are the same-->
      <component
          v-if="form && form.signatures && form.signatures.length && show"
          :is="show" key="active"
          :ref="show"
          v-model="form"
          :parentIndex="Number(show.charAt(show.length - 1))"
          @finishButtonText="finishButtonText=$event"
          @continueButtonText="continueButtonText=$event"
      />
      <div v-else key="else">You have no documents to sign.</div>

      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />

    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../../loadHelpers";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import DocumentSignature from "@/components/common/questionnaires/fieldsets/documents/Signature";

export default {
  name: 'DocumentSigning',
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase
  },
  data() {
    return {
      name: 'DocumentSigning',
      title: "Document Signing",
      url: 'document_signing',
      finishButtonText: 'Finish',
      continueButtonText: 'Continue',
      order: [ // imported components required here to load
        // no field-sets loaded here; for multiple field-sets that are the same use below config that is then used in load Helpers
      ],
      expiryAccessCodeOnFinish: true,
      multipleSameFieldSetsConfig: {
        component: DocumentSignature,
        componentBaseName: 'DocumentSignature',
        formFieldSetData: 'signatures'
      }
    };
  }
};
</script>
