<template>
  <div>
    <template v-if="signature.image && data">
      <div class="signature-area-signed" @click="show.modal=true">
        <img :src="signature.image" alt="Signature"/>
        <span class="date">Date:   {{ formatDate(data) }}</span>
      </div>
    </template>
    <template v-else>
      <div class="position-relative" @click="show.modal=true">
        <div class="ribbon-point-down-bg">
          <!--          <div class="ribbon-point-down-stripe">-->
          <!--            <div class="ribbon-point-down-fg">-->
          Sign<br>Here
          <!--            </div>-->
          <!--          </div>-->
        </div>

        <div class="signature-area d-flex" @click="show.modal=true">
          <h3>
            <i
                class="i-Pen-2 text-40"
                style="vertical-align: middle"
            ></i>
            Click to Sign</h3>
        </div>
      </div>
    </template>

    <SignatureCreateModal
        v-model="show.modal"
        :signatureData="signature"
        :sectionSigned="data"
        @updateDocumentSignature="signature.image = $event"
        @sign="sign"
    />
  </div>
</template>

<script>
import {dateFormat} from "@/mixins/dateFormat";
import {skipHelpers} from "../questions/helpers/skipHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import SignatureCreateModal from "@/components/common/documents/signature/SignatureCreateModal";

export default {
  name: "InputSignature",
  components: {SignatureCreateModal},
  mixins: [saveHelpers, skipHelpers, dateFormat],
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    db: {
      type: Object,
      required: true,
    },
    args: {
      type: Object,
      required: true,
    },
    noSave: {
      type: Boolean,
      default: false,
    },
    errorsPost: {
      type: Array,
      default: () => {
        return [];
      },
    },
    signatureData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      serverErrors: [],
      jsErrors: [],
      show: {
        modal: false
      }
    };
  },
  computed: {
    data: {
      set(value) {
        this.$emit("input", value);
        if (this.noSave) return;
        this.$nextTick(() => {
          this.save();
        });
      },
      get() {
        return this.value;
      },
    },
    signature: {
      set(value) {
        this.$emit('signatureUpdate', value)
      },
      get() {
        return this.signatureData
      }
    }
  },
  methods: {
    sign(date) {
      // save date now to section signature date in document json data
      this.data = date
      if (date) {
        this.toast('Document section signed')
      } else {
        this.toastError('Document section un-signed')
      }
      this.show.modal = false
      this.$emit('signed')
    }
  }
};
</script>
