<template>
  <QuestionBase
      :id="`signatureDate${parentIndex}${index}`"
      :question="question"
      :errors="[...serverErrors, ...jsErrors]"
      :valid="isValid">


    <div v-dompurify-html="questionData.displayHTML"></div>

    <div class="question-box with-arrow mb-36 mt-30">

      <div v-dompurify-html="questionData.text"/>

      <InputSignature
          v-model="data"
          ref="input"
          :db="db"
          :args="args"
          :signatureData="signature"
          @skipped="skipped=$event"
          @serverErrors="serverErrors=$event"
          @jsErrors="jsErrors=$event"
          @signatureUpdate="signature = $event"
          @signed="$emit('signed')"
      />

    </div>
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputSignature from "@/components/common/questionnaires/question/inputs/InputSignature";

export default {
  name: 'SignatureSection',
  mixins: [questionLoadHelpers],
  components: {
    InputSignature,
    QuestionBase
  },
  mounted() {
    if (this.data && !this.signatureData.image) {
      // reset data to null if there is a date and no signature image
      this.$refs.input.data = null
    }
  },
  props: {
    value: {
      type: String,
      required: false
    },
    index: {
      type: Number,
      required: true
    },
    parentIndex: {
      type: Number,
      required: true
    },
    signatureData: {
      type: Object,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: `${this.questionData.question}`,
        subTitle: this.questionData.subTitle,
        tip: this.questionData.tip
      }
    },
    isValid() {
      return !!this.data
    },
    questionData() {
      return this.signatureData.document.data.documentSignatureTemplate.sections[this.index]
    },
    signature: {
      set(value) {
        console.log('set signature', value)
        this.$emit('signatureUpdate', value)
      },
      get() {
        return this.signatureData
      }
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'document_signature_data',
        saveField: `signatureDate${this.parentIndex}${this.index}`,
        formPath: `signatures.${this.parentIndex}.document.data.documentSignatureTemplate.sections.${this.index}.signatures.client_${this.signatureData.client}.date`,
        formPathSave: `data.documentSignatureTemplate.sections.${this.index}.signatures.client_${this.signatureData.client}.date`,
        jsonSaveField: 'date',
        objectId: this.signatureData.id
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'Please sign this section to proceed'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>

<style scoped>

.signature {
  font-style: italic;
}

</style>
