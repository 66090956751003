import { render, staticRenderFns } from "./DocumentSignatureLegalStatus.vue?vue&type=template&id=67a1e030"
import script from "./DocumentSignatureLegalStatus.vue?vue&type=script&lang=js"
export * from "./DocumentSignatureLegalStatus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports