<template>
  <div>
    <b-row>
      <b-col>
        <p>Type your signature</p>
      </b-col>
      <b-col class="text-right">
        <b-button v-b-toggle.style-options variant="link">Style Options</b-button>
      </b-col>
    </b-row>

    <div class="p-1 signature-pad text-center mb-24 ">
      <b-input
          v-model="form.name"
          @input="convert"
          placeholder="Type your name here"
          class="mb-12 text-center" :style="{'font-family': selected.font, 'font-size': '2.5rem'}"
      />
      <img
          v-show="false"
          :src="dataUri"
          alt="signature preview"
      />
    </div>

    <b-collapse id="style-options" class="mt-2">
      <b-form-group label="Font Options" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
            id="radio-group-1"
            v-model="selected.font"
            @change="convert"
            :options="fontOptions"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
        ></b-form-radio-group>
      </b-form-group>
    </b-collapse>

  </div>
</template>

<script>

import {httpQuestionnaire} from "@/services";

require('text-image')
import {toast} from '@/mixins/toast'
import axios from "axios";

export default {
  name: 'DocumentSignatureType',
  mixins: [
    toast
  ],
  props: {
    signatureData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        name: null
      },
      dataUri: null,
      selected: {
        font: 'Brush Script MT, Brush Script Std, cursive'
      },
      fontOptions: [
        {text: 'Brush Script', value: 'Brush Script MT, Brush Script Std, cursive'},
        {text: 'Bradley Hand', value: 'Bradley Hand, cursive'},
        {text: 'Snell Roundhand', value: 'Snell Roundhand, cursive'},
        {text: 'Apple Chancery', value: 'Apple Chancery, cursive'}
      ]
    }
  },
  methods: {
    async convert() {
      if (!this.form.name) return this.dataUri = null
      // convert text message to image element
      var style = {
        font: this.selected.font,
        align: 'center',
        color: 'black',
        size: 30,
        background: 'transparent',
        stroke: 0.5,
        strokeColor: 'rgba(0, 0, 0, 1)'
      };
      let ti = window.TextImage(style)

      // convert text message to base64 dataURL
      this.dataUri = ti.toDataURL(this.form.name);

    },
    async save() {
      // guard if no signature
      if (!this.dataUri) {
        return this.toastError('Please type your signature first')
      }

      this.signatureImage = fetch(this.dataUri)
          .then(response => response.blob()
          ).then(
              blob => this.signatureImage = blob
          )
      let signatureData = await this.getAWSSignature()
      let image = await this.uploadToAmazon(signatureData, this.signatureImage);

      let patchData = {
        id: this.signatureData.id,
        image: image
      }

      httpQuestionnaire.patch('document_signature_detail', patchData).then(
          (response) => {
            this.working = false
            this.$emit('saved', response.data)
          }
      ).catch(
          error => {
            console.log(error)
            this.working = false
            this.toastError('Error uploading signature')
          }
      )


    },
    async getAWSSignature() {
      // get aws s3 signature from kernel server
      let path = `signature${this.signatureData.id}${new Date().toISOString()}.png`
      let data = {
        contentType: 'image/png', // content type of image from signature pad is always png
        filePath: path,
        signature: this.signatureData.id,
      };

      let instance = axios.create({
        headers: {
          Authorization: this.$store.getters.accessCode ? `Code ${this.$store.getters.accessCode}` : `JWT ${this.$store.getters.tokens.access}`
        }
      })

      return instance.post(`${process.env.VUE_APP_BACKEND_URL}/api/upload_pre_sign`,
          data).then((response) => {
        return response.data
      }).catch((error) => {
        this.toast('Error uploading image', 'Error', 'danger')
        console.log(error);
      });
    },
    async uploadToAmazon(response, imageData) {
      // upload to aws s3 with signature
      let formData = new FormData();
      let keys = Object.keys(response.signature);
      for (let i in keys) {
        formData.append(keys[i],
            response.signature[keys[i]]);
      }
      // attach file to upload on form as blob
      formData.append('file', imageData);

      return axios.post(response.postEndpoint, formData).then(() => {
        return response.signature.key;
      }).catch((error) => {
        console.log(error);
        return false;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.signature-pad {
  border-color: darkgrey;
  border-style: dashed;
  border-width: thin;
  border-radius: 15px;
}

.form-control {
  background-color: #fff;
  border: none;
  font-weight: 200;
  font-size: 2.5rem;
  height: 80px;
  padding-left: 20px;
}
</style>