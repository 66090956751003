<template>
  <div>
      <div v-if="!signatureData.disclosure_consent_approved_date" class="d-flex justify-content-between">
        <div class="p-12">
          <p >Before you begin, you must confirm legalities of signing this document.</p></div>
        <div>
          <b-button @click="$emit('confirm')">Confirm</b-button>
        </div>
        <hr>
      </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentSignatureLegalStatus',
  props: {
    signatureData: {
      type: Object,
      required: true
    }
  }
}
</script>
