<template>
  <b-modal
      v-model="showModel"
      aria-label=""
      body-class="modal-form"
      modal-class="modal-style-one modal-background-white questionnaire"
      scrollable
      size="lg"
      title="Sign"
      no-close-on-backdrop
      @ok="ok"
      @shown="shown"
  >

    <b-row v-if="show.changeSignature">
      <b-col>
        <b-card class="option-button text-center mb-30 pt-40 pb-40"
                @click="showSignaturePad">
          Draw Signature
        </b-card>
      </b-col>
      <b-col>
        <b-card class="option-button text-center mb-30 pt-40 pb-40"
                @click="showUploadSignature"
        >
          Upload Signature
        </b-card>
      </b-col>
      <b-col>
        <b-card class="option-button text-center mb-30 pt-40 pb-40"
                @click="showTypeSignature"
        >
          Type Signature
        </b-card>
      </b-col>
    </b-row>

    <div v-if="show.signaturePad">
      <DocumentSignaturePad
          ref="signatureDraw"
          :signatureData="signatureData"
          @working="setWorking"
          @saved="saveSignatureDraw"
      />
    </div>

    <div v-if="show.uploadSignature">
      <p>Upload your signature from a file</p>
      <DocumentSignatureDropZoneCard
          ref="documentDropzone"
          :signature="signatureData"
          @saved="saveSignatureUpload"
          @working="setWorking"
      />
    </div>

    <div v-if="show.typeSignature">
      <DocumentSignatureType
          ref="signatureType"
          :signatureData="signatureData"
          @saved="saveSignatureType"
          @working="setWorking"
      />
    </div>

    <template v-if="showSignature">
      <p>Your signature</p>
      <div class="p-4 signature-pad text-center mb-24 ">
        <img :src="signatureData.image" alt="client signature" />
      </div>
    </template>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <div>
          <template v-if="show.changeSignature">
            <b-button variant="link" @click="showModel=false">Cancel</b-button>
          </template>

          <template v-if="show.signaturePad">
            <b-button v-if="show.signaturePad" variant="link" @click="undoDraw">Undo</b-button>
          </template>

          <template v-if="showSignature">
            <b-button @click="show.changeSignature=true" variant="link">Change Signature</b-button>
            <b-button v-if="sectionSigned" @click="unSign" variant="link">Un-Sign</b-button>
          </template>
        </div>

        <div class="d-flex align-items-center pl-48">

          <template v-if="show.signaturePad">
            <b-button
                size="sm"
                variant="link"
                @click="showModel=false"
            >
              Cancel
            </b-button>
            <b-button
                class="ml-2"
                size="md"
                variant="secondary ripple"
                :disabled="working"
                @click="saveDraw"
            >
              Sign Section
              <b-spinner
                  v-if="working"
                  class="small-spinner align-middle ml-1"/>
            </b-button>
          </template>

          <template v-if="show.typeSignature">
            <b-button variant="link" @click="showModel=false">Cancel</b-button>
            <b-button
                class="ml-2"
                size="md"
                variant="secondary ripple"
                :disabled="working"
                @click="saveType"
            >
              Sign Section
              <b-spinner
                  v-if="working"
                  class="small-spinner align-middle ml-1"/>
            </b-button>
          </template>

          <template v-if="show.uploadSignature">
            <b-button variant="link" @click="showModel=false">Cancel</b-button>
            <b-button
                class="ml-2"
                size="md"
                variant="secondary ripple"
                :disabled="working"
                @click="saveSignatureUpload"
            >
              Sign Section Upload
              <b-spinner
                  v-if="working"
                  class="small-spinner align-middle ml-1"/>
            </b-button>
          </template>

          <template v-if="showSignature">
            <b-button
                size="sm"
                variant="link"
                @click="showModel=false"
            >
              Cancel
            </b-button>
            <b-button
                class="ml-2"
                size="md"
                variant="secondary ripple"
                :disabled="working"
                @click="sign"
            >
              Sign Section
              <b-spinner
                  v-if="working"
                  class="small-spinner align-middle ml-1"/>
            </b-button>
          </template>

        </div>
      </div>
    </template>

  </b-modal>
</template>


<script>

import {toast} from '@/mixins/toast'
import DocumentSignatureDropZoneCard from "@/components/common/documents/signature/DocumentSignatureDropZoneCard";
import DocumentSignatureType from "@/components/common/documents/signature/DocumentSignatureType";
import DocumentSignaturePad from "@/components/common/documents/signature/DocumentSignaturePad";

export default {
  name: 'SignatureCreateModal',
  mixins: [toast],
  components: {
    DocumentSignaturePad,
    DocumentSignatureType,
    DocumentSignatureDropZoneCard,
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    signatureData: {
      type: Object,
      required: true
    },
    selectedDocumentSection: {
      type: Object,
      required: false
    },
    sectionSigned: {
      type: String,
      required: false
    }
  },
  computed: {
    showModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    showSignature() {
      return !this.show.changeSignature && !this.show.typeSignature && !this.show.uploadSignature && !this.show.signaturePad
    }
  },
  data() {
    return {
      signatureImage: null,
      working: false,
      show: {
        changeSignature: false,
        signaturePad: false,
        uploadSignature: false,
        typeSignature: false
      }
    }
  },
  methods: {
    ok(bvEvent) {
      bvEvent.preventDefault()
    },
    shown() {
      this.show.changeSignature = !this.signatureData.image;
      this.show.signaturePad = false
      this.show.uploadSignature = false
      this.show.typeSignature = false
    },
    sign() {
      this.$emit('sign', new Date().toISOString())
    },
    unSign() {
      this.$emit('sign', null)
    },
    async saveSignatureDraw(data) {
      this.closeModal()
      this.working = false
      this.$emit('updateDocumentSignature', data.image)
      this.sign()
    },
    undoDraw() {
      this.$refs.signatureDraw.undo()
    },
    async saveSignatureType(data) {
      this.closeModal()
      this.working = false
      this.$emit('updateDocumentSignature', data.image)
      this.sign()
    },
    async saveSignatureUpload(data) {
      this.closeModal()
      this.working = false
      this.$emit('updateDocumentSignature', data.image)
      this.sign()
    },
    saveType() {
      this.$refs.signatureType.save()
    },
    saveDraw() {
      this.$refs.signatureDraw.save()
    },
    closeModal() {
      this.showModel = false
    },
    showSignaturePad() {
      this.show.signaturePad = true
      this.show.changeSignature = false
      this.show.typeSignature = false
      this.show.uploadSignature = false
    },
    showTypeSignature() {
      this.show.signaturePad = false
      this.show.changeSignature = false
      this.show.typeSignature = true
      this.show.uploadSignature = false
    },
    showUploadSignature() {
      this.show.signaturePad = false
      this.show.changeSignature = false
      this.show.typeSignature = false
      this.show.uploadSignature = true
    },
    setWorking(event) {
      this.working = event
    }
  }

}
</script>

<style scoped lang="scss">

.signature-pad {
  border-color: darkgrey;
  border-style: dashed;
  border-width: thin;
  border-radius: 20px;
}

.option-button {
  transition: 0.1s;

  .card-body {
    padding: 0 0 0.6rem;
  }

  .document-tools {
    position: absolute;
    right: 15px;
    padding: 0;
    top: 15px;
    opacity: 0;
    transition: 0.1s;

    > .dropdown > button {
      padding: 0;

      i {
        transition: 0.1s;
      }

      &:hover {
        i {
          color: black !important;
        }
      }
    }
  }

  .document-type {
    min-height: 41px;
  }

  .document-icon {
    margin-top: 30px;
    font-size: 55px;
    height: 70px
  }

  &.card-document-small {
    padding-top: 10px !important;

    .icon-wrapper {
      transform: scale(.7)
    }

    .document-tools {
      display: none;
    }

    .document-type {
      font-size: 12px;
      margin-top: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-footer {
    text-align: left;
    border-radius: 0 0 10px 10px;
    min-height: 108px;

    small {
      .doc-attribute {
        vertical-align: top;
        width: 30%;
        display: inline-block;
        text-align: right;
        white-space: nowrap;
      }

      .doc-data {
        vertical-align: top;
        padding-left: 7px;
        text-overflow: ellipsis;
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        font-weight: 500;
      }
    }
  }

  &:hover {
    margin-top: 5px;
    margin-bottom: 25px !important;
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    .document-tools {
      opacity: 1;
    }
  }

  &.card-document-hide-footer {
    .card-footer {
      display: none
    }
  }
}
</style>
